/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const ApiIntegration = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  useScript(withPrefix("js/sticky-header.js"))

  useScript(withPrefix("animations/apiintegration/apiintegration.js"))

  useScript(withPrefix("animations/apiintegration/apiintegrationlotte.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://documentesign.com/api-integration/#webpage",
        url: "https://documentesign.com/api-integration/",
        name: "Fast, Easy Document eSignature API Integration",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/#website",
        },
        primaryImageOfPage: {
          "@id":
            "https://documentesign.com/static/bcc56467c53b14453fd65055583f769a/home-benefits-img.jpg#primaryimage",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Seamlessly integrate Digital and Electronic Signature with most simple and robust digital signature document eSign API.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/api-integration/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO
          title="Fast, Easy Document eSignature API Integration - Document eSign"
          description="Seamlessly integrate Digital and Electronic Signature with most simple and robust digital signature document eSign API"
          schemaMarkup={schema}
        />

        <div className="feature-details api-integration">
          <div className="intro show-on-scroll ani-in">
            <div className="container position-relative mb-md-n5">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center pt-lg-5">
                  <div className="h4 pt-5 pb-3">API Integration</div>
                  <h1 className="mb-lg-n4">
                    Fast, easy document eSignature <br /> API integration
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3 mt-md-n5 text-center">
                  <div
                    id="apiintegration_lottie_container"
                    className="feature-lottie-ani"
                  ></div>
                </div>
              </div>
            </div>
            <div className="bg-shape d-none d-lg-block">
              <svg
                width="2560px"
                height="100px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="none"
                x="0px"
                y="0px"
                viewBox="0 0 2560 100"
                xmlSpace="preserve"
                enableBackground="new 0 0 2560 100"
              >
                <polygon points="2560 0 2560 100 0 100"></polygon>
              </svg>
            </div>
          </div>
          <div className="container mt-md-5 pt-md-5">
            <div className="row pt-md-4">
              <div className="col-md-6 offset-md-3 text-center show-on-scroll ani-in">
                <p className="text-light-100 pt-4 pt-md-0">
                  Document esign digital signature API provides the fastest and
                  feature-rich APIs for a seamless integration of your
                  applications. We allow you to add PKI based digital signing
                  capabilities to your software, web apps and business process.
                  Our REST-based powerful API library helps you to seamlessly
                  automate and manage the online digital signing process and
                  invisibly pass along with customer details across to the
                  Document esign platform.
                </p>
                <a
                  href="https://documentesign.com/contact/"
                  target="_blank"
                  className="btn btn-dark px-md-5 mt-2 mb-2"
                >
                  Contact Us
                </a>
                <br />
                {/* <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small> */}
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="card features-bx mb-5">
              <img
                src={withPrefix("images/api-top-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />

              <div className="card-body px-lg-5 mt-md-n5 py-0">
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-window-maximize"></i>
                    </div>

                    <div className="h4">Scalable and simple</div>
                    <p className="text-light-100">
                      Our digital signature API is built on modern design
                      principles like REST and JSON which lets you effortlessly
                      add signature fields to documents forms, app interfaces,
                      and more with utmost security.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-cog"></i>
                    </div>

                    <div className="h4">Document automation</div>
                    <p className="text-light-100">
                      Document esign digital esignature API automates your
                      document creation and signature requests by enabling you
                      to automatically generate quotes, contracts, and proposals
                      and display them in your app.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-dollar-sign"></i>
                    </div>

                    <div className="h4">Transparent pricing</div>
                    <p className="text-light-100">
                      Document esign offers free eSign plans for your Esignature
                      API integration that are transparent and tailored to your
                      requirements. Contact us to discuss your needs.
                    </p>
                  </div>
                  <div className="col-sm-6 mb-4 mb-sm-5 show-on-scroll ani-in">
                    <div className="feature-icon mb-3">
                      <i className="fas fa-stream"></i>
                    </div>

                    <div className="h4">High flexibility</div>
                    <p className="text-light-100">
                      You have the flexibility and complete control over the
                      signing process with Document esign API. Create your own
                      events and much more with automatically pre-fill
                      information that seamlessly flows in your site.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-bx-cta text-center show-on-scroll ani-in">
                <Link to="/features/" className="btn btn-outline-dark">
                  Explore more features{" "}
                  <i className="fas fa-arrow-right pl-2"></i>
                </Link>
              </div>
              <img
                src={withPrefix("images/api-bottom-particles.svg")}
                className="img-fluid show-on-scroll ani-in"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ApiIntegration
